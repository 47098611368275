
import { useFundBatch } from "@/network/fundBatch";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save] = useFundBatch(id);

    const refForm = ref(null);

    onMounted(() => {
      find().then((p) => {
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

      });
    })

    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((p) => {
    //       setTimeout(() => refForm.value.clearValidate(), 1);
    //     });
    //   }
    // });

    const doSave = () => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save().then(() => {
              resolve("success");
              visible.value = false;
              emit("change", form);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      user,
      refForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: {
        year: [
          {
            required: true,
            message: "请填写年度!",
            trigger: "blur",
          },
        ],
        batch: [
          {
            required: true,
            message: "请填写批次!",
            trigger: "blur",
          },
        ],
      },
    };
  },
});
