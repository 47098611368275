import request from "@/utils/request";
import { reactive, onMounted, ref, Ref } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { copyFrom } from "./common";

export function useQuery(filter) {
  const data = reactive({
    list: [],
    count: 0,
  });

  function query(replaceFilter = null) {
    Object.assign(filter, replaceFilter ?? {});
    request
      .get(`/fundBatch/query`, {
        params: filter,
      })
      .then((res) => {
        data.list = res.list;
        data.count = res.count;
      });
  }

  onMounted(() => {
    query();
  });

  return { data, query };
}

export function useFundBatch(id: Ref<number> = null): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);
  const empty = {
    id: null,
    year: null,
    batch: null,
  };
  const result = reactive(copyFrom(empty));

  function find(idValue: number) {
    isLoading.value = true;
    const v = idValue ? idValue : id.value;
    if (v) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      return request
        .get(`/fundBatch/${v}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          if (res != null) {
            copyFrom(result, empty, res);
          } else {
            copyFrom(result, empty);
          }
          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      copyFrom(result, empty);
      return Promise.resolve(result);
    }
  }

  function save(config = null) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id.value) {
      ps = request
        .put(`/fundBatch/${id.value}`, result, config)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/fundBatch`, result, config)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  return [isLoading, result, find, save];
}

export const useAction = (): any[] => {
  const isLoading = ref(false);
  const cancel = ref(null);
  const toggle = (id) => {
    return request
      .put(`/fundBatch/toggleStatus/${id}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  function remove(idValue: number): void {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .delete(`/fundBatch/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }
  function currentUse(): any {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .get(`/fundBatch/current/use`)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, toggle, remove, currentUse];
};
