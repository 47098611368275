
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useQuery, useAction } from "@/network/fundBatch";
import Base from "@/views/layout/Base.vue";
import Manage from "./manage.vue";
export default defineComponent({
  components: { Base, Manage },
  setup() {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
    });

    const manageSetting = reactive({
      id: null,
      visible: false,
    });

    const { data, query } = useQuery(filter);

    const [isProcessing, toggle, remove] = useAction();
    const edit = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };
    return {
      user,
      isProcessing,
      toggle,
      remove,
      edit,
      manageSetting,
      pagerData: data,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
