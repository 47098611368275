<template>
  <el-dialog v-model="visible" title="批次管理">
    <el-card v-loading="isLoading">
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-form-item label="年度" prop="year">
            <el-input v-model.number="form.year" placeholder="年度"></el-input>
          </el-form-item>

          <el-form-item label="批次" prop="batch">
            <el-input v-model.number="form.batch" placeholder="批次"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-popconfirm title="确认保存吗！？" @confirm="doSave()" placement="top">
          <template #reference>
            <el-button type="primary" :loading="isLoading" icon="el-icon-check">
              {{ isLoading ? "处理中" : "保 存" }}
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { useFundBatch } from "@/network/fundBatch";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save] = useFundBatch(id);

    const refForm = ref(null);

    onMounted(() => {
      find().then((p) => {
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

      });
    })

    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((p) => {
    //       setTimeout(() => refForm.value.clearValidate(), 1);
    //     });
    //   }
    // });

    const doSave = () => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save().then(() => {
              resolve("success");
              visible.value = false;
              emit("change", form);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      user,
      refForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: {
        year: [
          {
            required: true,
            message: "请填写年度!",
            trigger: "blur",
          },
        ],
        batch: [
          {
            required: true,
            message: "请填写批次!",
            trigger: "blur",
          },
        ],
      },
    };
  },
});
</script>

<style scoped></style>