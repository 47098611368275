<template>
  <Base title="资金批次管理" titleIcon="el-icon-s-claim">
    <div>
      <div class="search-row">
        <el-space>
          <el-button type="primary" icon="el-icon-plus" @click="edit(null)"
            >添加批次</el-button
          >
        </el-space>
      </div>
    </div>
    <Manage :setting="manageSetting" @change="query({ pageNum: 1 })" v-if="manageSetting.visible"></Manage>
    <el-table :data="pagerData.list">
      <el-table-column label="编号" prop="id"></el-table-column>
      <el-table-column label="年度" prop="year"></el-table-column>
      <el-table-column label="批次" prop="batch"></el-table-column>
      <el-table-column label="状态" prop="statusName">
        <template #default="{ row: item }">
          <el-button
            :loading="isProcessing"
            :type="item.statusName == '停止' ? 'info' : 'success'"
            @click="toggle(item.id).then((p) => query({ pageNum: 1 }))"
            size="mini"
            >{{ item.statusName }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260">
        <template #default="{ row: item }">
          <!-- <el-button
            type="primary"
            @click="edit(item.id)"
            size="mini"
            icon="el-icon-edit"
          ></el-button> -->
          <el-popconfirm
            title="确认删除吗！？"
            @confirm="remove(item.id).then(() => query())"
            placement="top"
          >
            <template #reference>
              <el-button
                type="primary"
                icon="el-icon-delete"
                size="mini"
                :loading="isProcessing"
              >
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="padding-left: 5px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      v-model:page-size="filter.pageSize"
      :total="pagerData.count"
      layout="sizes,total, prev, pager, next"
      v-model:currentPage="filter.pageNum"
    ></el-pagination>
  </Base>
</template>
<script lang="ts">
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useQuery, useAction } from "@/network/fundBatch";
import Base from "@/views/layout/Base.vue";
import Manage from "./manage.vue";
export default defineComponent({
  components: { Base, Manage },
  setup() {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
    });

    const manageSetting = reactive({
      id: null,
      visible: false,
    });

    const { data, query } = useQuery(filter);

    const [isProcessing, toggle, remove] = useAction();
    const edit = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };
    return {
      user,
      isProcessing,
      toggle,
      remove,
      edit,
      manageSetting,
      pagerData: data,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
</script>
<style lang="scss" scoped>
</style>